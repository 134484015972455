<template>
  <div ref="body">
    <custom-header active="design" :subActive="current"></custom-header>

    <div class="content-container">
      <div class="box relative">
        <img :src="sessions1.img" :alt="sessions1.name" class="fill" />
        <div class="more-box flex flex-direction absolute text-bold">
          <h2 class="text-white">{{sessions1.name}}</h2>
          <div class="more-btn" @click="toLibrary(sessions1)">See more</div>
        </div>
      </div>
      
      <h1>Past Editions of SZCW Exhibitions</h1>
      <div class="main-bar flex flex-wrap">
        <div v-for="(item, idx) in sessions2" :key="idx" class="row">
          <h2 class="text-center text-bold">{{item.name}}</h2>
          <div class="img-box flex flex-wrap">
            <template v-for="pic in item.sessionDetailList">
              <img :src="pic.img" :key="pic.sessionDetailId" alt="" class="img fill" />
            </template>
          </div>
          <div class="more" @click="toLibrary(item)">See more</div>
        </div>
      </div>
    </div>
    
    <custom-footer :friendLink="friendLink"></custom-footer>
    
    <!-- 回到顶部 -->
    <scroll-top @scrollTop="scrollTop"></scroll-top>
  </div>
</template>

<script>
import { Swipe, SwipeItem, Icon } from 'vant';
import customHeader from "./components/head.vue";
import customFooter from "./components/foot.vue";
import scrollTop from "./components/scrollTop.vue";
import commonMixin from './commonMixin.js';
import { getSessionList } from '@/api/index.js';

export default {
  // name: '视频&图片',
  components: {
    'custom-header': customHeader,
    'custom-footer': customFooter,
    'scroll-top': scrollTop,
    [Icon.name]: Icon,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  mixins: [commonMixin],
  metaInfo: {
    title: 'SZ Creative Week | Highlights of 35th, 36th and 37th Edition', // set a title
    meta: [
      { name: 'keyWords', content: 'Furniture Fair,Home Design,Furniture Exhibitor,Home Furnishing Forum,Design Week,Home Design Week' },
      { name: 'description', content: 'Check Out the Highlights (Photos and Videos) of the 35th, 36th, and 37th Edition of Shenzhen China International Furniture Exhibition & Creative Week.'},
    ],
  },
  data() {
    return {
      current: 'picture',
      sessions1: {},
      sessions2: [],
    }
  },
  created() {
    this.getSessions();
  },
  methods: {
    getSessions() {
      getSessionList().then(res => {
        let list = res.resultData || [];
        if (list.length > 0) {
          this.sessions1 = list.shift();
          this.sessions2 = list;
        }
      })
    },
    toLibrary({sessionId, name}) {
      this.$router.push({
        path: '/library',
        query: {
          id: sessionId,
          name: encodeURIComponent(name)
        }
      })
    }
  },
}
</script>

<style scoped lang="less">
// 移动端
@media screen and (max-width: 1023px) {
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  .content-container {
    width: 375px;
    margin: 0 auto 20px;
    
    h1 {
      font-size: 18px;
      text-align: center;
      margin-top: 30px;
    }
    
    .box {
      width: 340px;
      height: 170px;
      margin: 0 auto 10px;
      color: #fff;
      text-align: center;
      
      img {
        border-radius: 4px;
      }
    }
    
    .more-box {
      bottom: 10px;
      left: 10px;
      z-index: 1;
      // width: 100px;
      // height: 60px;
      line-height: 1.4em;
      font-size: 16px;
      color: #fff;
      text-align: left;
      
      h2 {
        font-size: 16px;
      }
      
      .more-btn {
        margin-top: 3px;
        width: 70px;
        font-size: 12px;
        line-height: 2em;
        border-radius: 4px;
        background-color: #00ABA5;
        color: #fff;
        text-align: center;
        font-weight: normal;
      }
    }
    
    
    .main-bar {
      width: 340px;
      margin: 0 auto;
      
      .row {
        width: 100%;
        margin-bottom: 30px;
        
        h2 {
          font-size: 14px;
          line-height: 3em;
          margin: 8px 0;
        }
        
        .img-box {
          width: 100%;
          color: #fff;
          text-align: center;
          
          
          .img {
            display: block;
            width: 160px;
            height: 80px;
            border-radius: 4px;
            margin-right: 15px;
            margin-bottom: 15px;
            
            &:nth-child(2n) {
              margin-right: 0;
            }
          }
        }
        
        .more {
          width: 100px;
          margin: 10px auto;
          font-size: 14px;
          line-height: 2em;
          border-radius: 4px;
          background-color: #00ABA5;
          color: #fff;
          cursor: pointer;
          text-align: center;
        }
        
      }
      
    }
  }
}

@media screen and (min-width: 1024px) {
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  .content-container {
    width: 280px;
    margin: 0 auto 20px;
    
    h1 {
      font-size: 8px;
      text-align: center;
      margin: 10px auto;
    }
    
    .box {
      width: 280px;
      // height: 80px;
      height: 140px;
      line-height: 140px;
      color: #fff;
      text-align: center;
      font-size: 6px;
      margin-bottom: 15px;
      
      img {
        border-radius: 1px;
      }
    }
    
    .more-box {
      bottom: 5px;
      left: 5px;
      z-index: 1;
      // width: 100px;
      // height: 60px;
      line-height: 10px;
      font-size: 6px;
      color: #fff;
      text-align: left;
      
      h2 {
        font-size: 6px;
      }
      
      .more-btn {
        margin-top: 3px;
        width: 30px;
        font-size: 3.6px;
        line-height: 8px;
        border-radius: 1px;
        background-color: #00ABA5;
        color: #fff;
        cursor: pointer;
        text-align: center;
      }
    }
    
    .main-bar {
      width: 100%;
      
      .row {
        width: 100%;
        margin-bottom: 12px;
        
        
        h2 {
          font-size: 6px;
          line-height: 14px;
          margin-bottom: 8px;
        }
        
        .img-box {
          width: 100%;
          color: #fff;
          text-align: center;
          border-radius: 1px;
          font-size: 6px;
          
          .img {
            display: block;
            width: 66px;
            height: 40px;
            border-radius: 1px;
            margin-right: 5px;
            
            &:nth-child(4n) {
              margin-right: 0;
            }
          }
        }
        
        .more {
          width: 30px;
          margin: 10px auto;
          font-size: 3.6px;
          line-height: 8px;
          border-radius: 1px;
          background-color: #00ABA5;
          color: #fff;
          cursor: pointer;
          text-align: center;
        }
      }
      
    }
  }
}

  
</style>